import React from "react";
import UserService from "../services/User";

export default () => {
    // 判断用户有没有本地有没有邀请链接，如果有就先跳转到邀请链接
    const nextUrl = window.sessionStorage.getItem('nextUrl');
    if (nextUrl) {
        window.location.href = nextUrl;
        // 如果已经跳转到nextUrl链接,将nextUrl从系统中删除,避免重复访问
        window.sessionStorage.removeItem('nextUrl')
    }
    else if(UserService.isTeacher()){
        window.location.hash="/t/report/boxes";
    }
    else if(UserService.isAdmin()) {
        window.location.hash="/a/teachers";
    }
    else{
        window.location.hash="/s/report/boxes";
    }
    return <div>Home</div>
}