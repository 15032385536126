import React from "react";
import {Menu} from 'antd';
import {Link} from "react-router-dom";

const {SubMenu} = Menu;

const recursion = (dataSource) => {
    if(!dataSource){
        return
    }
    return (
        dataSource.map((menu) => {
            menu.key = menu.key || menu.title;
            if (menu.children) {
                return (
                    <SubMenu key={menu.key} title={<span>
                        {menu.title}</span>}>
                        {recursion(menu.children)}
                    </SubMenu>
                )
            } else {
                return (<Menu.Item key={menu.key}>
                    {(menu.url && menu.url.startsWith("http"))?
                        <a href={menu.url} target="_blank">{menu.title}</a>:
                        <Link to={menu.url}>
                            {menu.title}
                        </Link>
                    }
                </Menu.Item>)
            }
        })
    )
};

export default ({data, ...others}) => {
    return <Menu{...others}>{recursion(data)}</Menu>
};