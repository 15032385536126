import {observable} from "mobx";

class BreadcrumbsStoreStore {
    @observable currentCourse = "当前课程";
    @observable courseClass = {};
    @observable course = {};
    @observable boxName;//当前作业箱名称
    @observable scoreItem;//当前作业的作业项目
    @observable nextReportId;//下一份作业ID
    @observable nextReportName;//下一份作业名称
    @observable courseName;//课程实验名称
    @observable boxId;//作业箱id
    @observable nameListName;//名单名称
    @observable room;//实验室

}

const breadcrumbsStore = new BreadcrumbsStoreStore();
export default breadcrumbsStore;