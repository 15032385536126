import {p2p,toJSON,toJSONList} from "./helper/parse";
const Parse=window.Parse;

const ParseObjectsCache={};

export default
function generateParseObject(Name){
    if(ParseObjectsCache[Name]){
        return ParseObjectsCache[Name];
    }
    class ParseObject extends Parse.Object {
        constructor() {
            super(Name);
        }

        get _id(){
            return this.id;
        }

        set _id(id){
            this.id=id;
        }

        toJSON(){
            const x=super.toJSON();
            x._id=x.objectId;
            delete x.objectId;
            return x;
        }

        // static create(plainObject){
        //     const pObject = new ParseObject();
        //     return pObject.save(plainObject).then(toJSON);
        // }

        // static update(plainObject){
        //     const pObject = ParseObject.createWithoutData(plainObject._id);
        //     const keys=Object.keys(plainObject).filter(k=>k!=="_id"&& k!=="createdAt" && k!=="updatedAt");
        //     for(const k of keys){
        //         pObject.set(k,plainObject[k]);
        //     }
        //     return pObject.save().then(toJSON);
        // }

        static getOne(id){
            const query = new Parse.Query(ParseObject);
            return query.get(id).then(toJSON);
        }

        static read(){
            const query = new Parse.Query(ParseObject);
            query.limit(9999);
            query.notEqualTo('isDeleted', true);
            return query.find().then(toJSONList).catch(e=>[]);
        }

        // static removeById(id){
        //     const pObject = ParseObject.createWithoutData(id);
        //     pObject.set('isDeleted',true)
        //     return pObject.save()
        // }

        static queryEqual(key,value){
            const query = new Parse.Query(ParseObject);
            query.equalTo(key, value);
            query.notEqualTo('isDeleted', true);
            query.limit(9999);
            return query.find().then(toJSONList).catch(e=>[]);
        }


        static getIDName() {
            return "_id";
        }
        static queryEqualFirst(key,value){
            const query = new Parse.Query(ParseObject);
            query.equalTo(key, value);
            query.notEqualTo('isDeleted', true);
            return query.first().then(toJSON);
        }
        static queryCount(key, value){
            const query = new Parse.Query(ParseObject);
            query.equalTo(key, value);
            query.notEqualTo('isDeleted', true);
            return query.count();
        }
    }
    Parse.Object.registerSubclass(Name,ParseObject);
    ParseObjectsCache[Name]=ParseObject;
    return ParseObject;
}
