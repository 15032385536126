export function p2p(p) {
    return new Promise(function (resolve, reject) {
        return p.then(resolve, reject);
    });
}
export function toJSON(doc) {
    if (doc) {
        return doc.toJSON();
    }
    else {
        return null;
    }
}
export function toJSONList(docs) {
    return docs.map(toJSON);
}
